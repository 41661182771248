import React, { useRef, useEffect } from 'react';
import { marked } from 'marked';

function Editor({ markdown, setMarkdown, isSyncScroll }) {
  const editorRef = useRef(null);
  const previewRef = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      if (!isSyncScroll) return;

      const source = e.target;
      const other = source === editorRef.current ? previewRef.current : editorRef.current;
      const scrollPercentage = source.scrollTop / (source.scrollHeight - source.clientHeight);
      other.scrollTop = scrollPercentage * (other.scrollHeight - other.clientHeight);
    };

    const editor = editorRef.current;
    const preview = previewRef.current;

    editor.addEventListener('scroll', handleScroll);
    preview.addEventListener('scroll', handleScroll);

    return () => {
      editor.removeEventListener('scroll', handleScroll);
      preview.removeEventListener('scroll', handleScroll);
    };
  }, [isSyncScroll]);

  const renderMarkdown = () => {
    return { __html: marked(markdown) };
  };

  return (
    <div className="editor">
      <div className="textarea-pane">
        <textarea ref={editorRef} value={markdown} onChange={(e) => setMarkdown(e.target.value)} />
      </div>
      <div ref={previewRef} className="preview-pane" dangerouslySetInnerHTML={renderMarkdown()} />
    </div>
  );
}

export default Editor;
